.header__content {
    position: relative;

    .header__collapse {
        margin: 0;
        width: 60px;
        height: 60px;

        &:hover {
            background-color: $transparent-background;
        }

        .icon-bar {
            width: 100%;
            background-color: #ddd;
        }

        .icon-bar + .icon-bar {
            margin-top: 8px;
        }
    }

    .header-nav {
        background-color: $transparent-background;
        @media(min-width: 768px) {
            height:50px !important;
        }
        .header__menu {
            li {

                padding: 0 20px;

                &:first-child{
                    @media(max-width: $screen-xs-max) {
                        border-top: 1px solid #5e9fa0;
                    }
                }

                @media(max-width: 992px) {
                    padding: 0 6px;
                }

                a {
                    color: $header-text;
                    font-family: 'Lato-Regular';

                    @media(max-width: $screen-xs-max){
                        font-weight: bold;
                        border-bottom:1px solid #5e9fa0;
                        border-right:1px solid #5e9fa0;
                        border-left:1px solid #5e9fa0;
                    }

                    &:hover, &:focus, &:active {
                        // color: #fff;
                        background-color: $transparent-background-hover;
                    }
                }

                &.menu-item-has-children {
                    .sub-menu {
                        display: none;
                        padding: 0;
                        min-width: 100%;
                        width:200px;
                        z-index: 8888;
                        @media (max-width: $screen-xs-max) {
                            display: block;
                        }

                        li {
                            @media(min-width: $screen-sm-min){
                                background-color: $transparent-background;
                            }
                            display: block;

                            a {
                                text-decoration: none;
                                display:block;
                                padding: 10px 15px;
                                @media (max-width: $screen-xs-max) {
                                    background-color: #5e9fa0;
                                    font-weight: normal;
                                }

                                &:hover {
                                    background-color: transparent;
                                    cursor:pointer;
                                }
                            }

                            &:hover {
                                // color: #fff;
                                background-color: $transparent-background-hover;
                            }
                        }
                    }

                    &:hover {
                        @media (min-width: $screen-sm-min) {
                            .sub-menu {
                                display: grid;
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }


    }

    .navbar-collapse {
        border-top: none;
        box-shadow: none;
        @media (max-width: $screen-xs-max) {
            background-color: $brand-primary;
            padding-bottom:15px;
            padding-top:15px
        }
    }

    @media (max-width: $screen-xs-max){
        .navbar-nav{
            margin:0;
        }
    }

    .navbar-toggle {
        border: 1px solid #ddd;
        background-color:$brand-primary;
    }

    .header-holder{

        position:relative;

        .mobile-nav-container
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }


}

.header__logo {
    background-color: transparent;
    opacity:0.8;
    z-index: 10;

    @media (min-width: $screen-sm-min) {
        max-width: 300px;
        height: auto;
        position: absolute;
    }
}


.header__link {
    .header__lead-heading {
        h2 {
            &:after {
                content: ' - Read More';
                color: white;
            }
        }
    }
}

.logo-holder{
    position:absolute;
    top:0;
    z-index:100;

    @media(max-width:550px){
        padding-left:0px;
    }

    @media(max-width:768px){
        position:relative;
    }

    .logo-img{
        position:absolute;
        left: 70px;
        top: 20px;

        @media(max-width:768px){
            position:relative;
            left:0;
            top:0;
        }
        @media(max-width:550px){
            height:100px;
        }

        @media(max-width:550px){
            height:80px;
        }
    }
    @media(max-width:768px){
        svg{
            display:none;
        }
    }


    .svg-bg {
        fill: #fff;
        fill-rule: evenodd;
        opacity:0.8;
    }
}

.message-holder {

    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;

    @media(max-width:992px){
        position:relative;
    }

    .the-cont{
        position:relative;

        @media(max-width:992px){
            margin:0;
            width:100%;
            padding:0;
        }

    }

    .message-content {
        padding: 10px 20px;
        text-align: right;
        z-index: 10;
        right: 15px;
        background-color: $brand-primary;
        position: absolute;
        bottom: 75px;

        @media(max-width:992px){
            position:relative;
            right:0;
            bottom:0;
        }

        h2, h3{
            color:white;
            margin-top:10px;
        }

    }
}

.header__banner-image {
    position: relative;
    z-index: 1;

    @media (min-width: $screen-sm-min) {}
}

.header__banner{
    height:auto !important;
}

.header__lead-heading {
    padding: 10px 20px;
    text-align: right;
    z-index: 10;
    right: 0;
    background-color: $transparent-background;

    h2, h3 {
        margin-top:10px;
        color: #fff;
    }

    h2{
        font-size:36px;
    }

    h3 {
        font-size: 30px;
        font-family: 'Lato-Light';
    }

    @media (min-width: $screen-sm-min) {
        position: absolute;
        margin-top: -200px;
    }
}

.header__menu {
    width: 100%;
    text-align: center;
    color: $header-text;

    li {
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            float: none;
        }
    }
}

.navbar-default {
    .navbar-nav {
        .active {
            a {
                background-color: $transparent-background;
                color: #fff !important;
            }
        }
    }
}

.navbar-default{
    background-color:#FFF;
}

.flex-control-nav{
    bottom:10px;
    z-index:2;

    @media(max-width:992px){
        position: relative;
        bottom: 0;
        background: #eee;
        padding-bottom: 10px;
        padding-top: 15px;
    }

    .flex-control-paging li a{
        border:1px solid grey;
    }
}


.flex-control-paging li a.flex-active{
    background:$brand-primary;

    &:hover, &:active, &:focus{
        background:$brand-primary;
    }
}

.flex-control-paging li a{
    background:$accent-grey;


    &:hover, &:active, &:focus{
        background:$accent-grey;
    }
}

.header__banner-image.non-home{
    img{
        width:100%;
    }
}

.logo-holder.non-home{

    padding:0;

    svg{
        display:none;
    }

    .logo-img {
        position: relative;
        background:white;
        opacity:0.8;
        left:0;
        top:0;
        max-width:250px;

        @media(max-width:350px){
            max-width:200px;
        }
    }
}

.non-home-nav{
    @media (min-width: $screen-sm-min ) {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
    }
    @media (max-width: 768px ) {
        position: absolute;
        width:100%;
        z-index:9999;
    }
 }

.flexslider{
    margin-bottom:0px !important;
    min-height:350px;
}

.banner-no-home {
    img {
        @media(max-width: 1150px) {
            min-height: 188px;
        }

        @media(max-width:768px){
            min-height:0;
        }
    }
}

.ipadhover{
    position: absolute;
    display: grid !important;
}

.home .home-nav{
    @media(max-width: 768px) {
        position: absolute;
        width:100%;
        top:137px;
        z-index: 9999;
    }

    @media(max-width: 550px) {
        top:77px !important;
    }

}