button, .btn-brand, .btn-brand-alternate {
    font-family: 'Lato-Bold';
    background-color: $highlight-green;
    color: #fff;
    border: none;
}

.btn-brand, .btn-brand-alternate {
    border-radius: 0;
    padding: 5px 30px 5px 30px;
}

.btn-brand-alternate{
    background-color: $highlight-green-dark;
}

.spec-right{
    .btn-brand{
        display:block;
        margin-bottom:10px;
        text-align: center;
        &:hover{
            text-decoration:none;
        }

    }
}
