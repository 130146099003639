h1, h2, h3, h4, h5, h6 {
    color: $brand-primary;
    font-family: 'Lato-Bold';
}

#content-description h2{
    font-family:"Lato-Regular";
}
#content-description h3{
    font-family:"Lato-Light";
}

.info-panel {
    background-color: $accent-grey;

    h2 {
        margin-top: 0;
    }

    @media (min-width: $screen-md-min) {
        padding: 30px 0 30px 0;
    }

    @media (max-width: $screen-sm-max) {
        padding: 20px;
    }
}

.card {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: #fff;
    padding: 0;

    @media (max-width: $screen-sm-max) {
        padding: 20px;
    }
}

figure {
    display: inline-block;
    padding: 3px;
}

.flexslider {
    border: none;
    margin-bottom: 48px;

    @media(max-width:768px){
        margin-bottom:0;
    }

    .flex-direction-nav {
        display: none;
    }
}


//Wordpress post content gallery
.gallery{
    img{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.gallery-columns-1{
    figure{
        width: 100%;
    }
}
.gallery-columns-2{
    figure{
        width: 50%;
    }
}
.gallery-columns-3{
    figure{
        width: 33%;
    }
}
.gallery-columns-4{
    figure{
        width: 25%;
    }
}

//Contact{
#map{
    height:240px;
}
.social-section{

    h3{
        text-align:center;
    }

    ul{

        width:100%;
        text-align:center;
        padding:0;

        li{
            list-style-type:none;
            display:inline-block;
            margin-right:30px;
        }
    }
}

//Bootstrap
.breadcrumb{
    padding: 15px 0px;
    background-color: transparent;
    margin-bottom:0;
}