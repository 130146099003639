// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-primary: #457879;
$accent-grey: #ced6d7;
$highlight-green: #899648;
$highlight-green-dark: #2a484a;
$transparent-background: rgba(21,21,21,0.8);
$transparent-background-hover: rgba(95,95,95,0.8);
$header-text: #fff;
