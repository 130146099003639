.image-panel, .info-panel {
    height: 283px;
    @media(max-width: $screen-sm-max){
        height: 100%;
    }
}

.image-panel {
    padding: 0;

    img {
        width: 100%;
        height: 100%;
        background:hsla(0,0%,8%,.8);
    }
}

.page-showcase {
    .card {
        @media (min-width: $screen-md-min) {
            padding: 40px 30px 40px 30px;

            .btn-brand {
                position: absolute;
                bottom: 30px;
            }
        }

        @media (max-width: $screen-sm-max) {
            .btn-brand {
                position: absolute;
                bottom: 20px;
                right: 20px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .card-right {
        .card {
            margin-left: -30px;
        }

        .btn-brand {
            right: 30px;
        }
    }

    .card-left {
        .card {
            margin-left: 30px;
        }

        .btn-brand {
            right: -30px;
        }
    }
}