/**
 * Front Page
 */


.custom-swipe{
    .custom-client-slide-holder {
        img{
            margin:0 auto;
        }
    }

    .swiper-button-prev{
        left:0;
    }
    .swiper-button-next{
        right:0;
    }
}

.front__content {
    margin-bottom: 75px;

    @media(max-width:768px){
        margin-bottom:10px;
    }

    p {
        line-height: 2;
    }

    h2 {
        margin-bottom: 20px;
    }
    &#front-description{
        margin-top: 30px;

        @media(max-width:768px){
            margin-top:10px;
        }

        h1 {
            @media(max-width: 768px) {
                margin-top: 0;
            }
        }

        p{
            font-size:13px;
        }
    }
}

.front-showcase__banner {
    background-color: $brand-primary;
    h3 {
        margin-top:10px;
        color: #fff;
        vertical-align: middle;

        @media(max-width:992px){
            padding: 15px 0;
            margin-top:0;
            margin-bottom: -1px;
        }
    }
}

.front-showcase__banner.bottom{
    height:50px;
}

.front-news__banner {
    display: inline-block;
    height: 300px;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
    }

    @media(max-width: $screen-xs-max){
        height: 200px;
        img{
            height: auto;
        }
    }
}

.front-news {
    .info-panel {
        margin-top: -150px;
        p{
            line-height: 1.4;
        }
    }

    @media (min-width: $screen-md-min) {
        .info-panel {
            padding: 30px;
        }

        .btn-brand-alternate {
            margin-left: 10px;
        }
    }

    @media (max-width: $screen-sm-max) {
        .info-panel, .card {
            height: 100%;
        }

        .card {
            padding: 15px;
        }

        .btn-brand, .btn-brand-alternate {
            width: 100%;
            margin: 10px auto 0 auto;
        }
    }
}

.front-news__image {
    padding-top: 15px;
    padding-right: 15px;
    max-height: 200px;

    @media (max-width: 475px) {
        padding-right:0;
    }
}

.front-products__single {
    height: 220px;
    margin-bottom:30px;

    @media (max-width: $screen-sm-max) {
        margin: 0 auto 20px auto;
    }

    .info-panel {
        height: 100%;
        padding: 15px;

        .card {
            padding: 10px;

            h4 {
                margin-bottom: 0;
            }

            .front-products__btn {
                position: absolute;
                padding: 5px 5px 5px 5px;
                width: 60px;
                right: 15px;
                bottom: 15px;
            }
        }

        &:after {
            content: "";
        }
    }
}

.front-products__img {
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}

.front-custom-img{
    height:220px;
    background-size:cover;
    background-repeat:no-repeat;
}

.front-customers__single {

    img{
        margin:0 auto;
    }

    @media (max-width: $screen-sm-max) {
        img {
            margin-top: 15px;
        }
    }
}

/**
 * Index Template
 */

#index-description {
    margin-bottom: 40px;
}

/**
 * Clients Template
 */

.case-study__info {
    h3{
        padding-left: 15px;
    }
    &.info-panel {
        @media (min-width: $screen-sm-min) {
            height: 300px;
        }
    }

    padding: 20px;
    margin-bottom: 30px;

    .card {
        padding: 10px;
    }
}

/**
 * Single Case Study
 */

.case-study__content {

}

.content-feature{
    margin-top: 40px;
}


/**
 * Showcase Alternative
*/

.showcase-alternative{
    margin-bottom:30px;

    .top-section{

        position:relative;
        border-top:10px solid $accent-grey;
        border-left:10px solid $accent-grey;
        border-right:10px solid $accent-grey;

        img{
            margin:0 auto;
            max-height:220px;
            height:220px;
        }
    }

    .card-alt{
        background:$accent-grey;
        position:relative;

        h2{
            margin-top:0;
            margin-bottom:0;
            color:$highlight-green-dark;
            padding:10px;
            font-size:24px;
        }
        .btn-alt{
            font-family: Lato-Bold;
            background-color: #899648;
            color: #fff;
            border: none;
            padding:5px 30px;
            position:absolute;
            right:0;
            bottom:0;

            &:hover{
                text-decoration:none;
                color:#000;
            }
        }

        .desc-alt{
            min-height:50px;
            padding:10px 10px 35px 10px;
        }
    }
}

/**
 * 404
*/

.fourzerofour{
    .page-header{
        border-bottom:none;
        margin:40px 0 0 0;
        padding-bottom:0;
    }
    h1{
        margin:30px 0;
    }

    ul{
        margin:30px 0;
    }

    .sub-menu{
        margin:0;
    }
}

/**
 * Specification Template
*/

.control-fluid{
    overflow:auto;
}

.spec-left{
    h2{
        margin-top:0;
        font-family:"Lato-Light";
    }
    h3,h4,h5{
        font-family:"Lato-Light";
    }
}

.spec-right{
    background-color:$accent-grey;

    @media(max-width:992px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }


    p{
        color:$highlight-green-dark;
        font-family:"Lato-Build", sans-serif;
    }

    ul {

        padding-left:15px;

        li {

            color: $highlight-green-dark;
            font-family: "Lato-Build", sans-serif;

            a {
                text-decoration: underline;
            }
        }
    }

    h2, h3, h4, h5{
        color:#899648;
        text-transform:uppercase;
        font-family: "Lato-Regular";
    }

    h2{
        margin-top:10px;
        font-size:24px;
    }
}

.range-view{
    margin-top:20px;
    text-align:center;
    border-top:1px solid #899648;
    border-bottom:1px solid #899648;
    position:relative;
    clear:both;

    .range-title{
        color:#899648;
        font-weight:bold;

        a{
            color:#899648 !important;
        }

        &:after{
            content:none;
        }
    }

    ul{

        padding-top:10px;
        margin-bottom:0;

        li{

            display:inline-block;
            list-style-type:none;
            color:#2a484a;
            text-transform:uppercase;

            a{
                color:#457879;
                display:inline-block;
                text-transform:uppercase;
            }

            &:after{
                content:" |";
            }

            &:last-child{
                &:after{
                    content:none;
                }
            }

            p{
                display: inline-block;
            }
        }

    }

}

/* Logos template */

.logo-container{
    margin-top:30px;

    .top-section{
        margin:20px 0;

        p{
            margin:20px 0 30px 0;
        }

    }

    .client-name{
        margin-top:10px;
    }

    .logo-item {
        margin-bottom: 30px;

        @media(max-width: 370px) {
            width: 100%;
        }

    }

}
.client-bottom-btn{
    margin-top:30px;
}

/* Template content */
.tpc-sidebar{

    color:white;
    margin-top: 25px;

    .form-section {
        background: #457879;
        padding:20px;
        margin:30px 25px;
    }

    .side-bar-section{
        margin: 30px 25px;
        background:#ced6d7;
        padding:20px;

        h3{
            margin-top:0;
            font-size:18px;
        }

        p{
            color:#333;
            margin-bottom:20px;

            &:last-child{
                margin-bottom:0;
            }
        }

        a{
            //color:#457879;
            color: #fff;
            display: block;
            text-align: center;
        }

        .sidebar-button{
            display:block;
            padding:10px 20px;
            background:#899648;
            color:white;
            text-align:center;
            margin-bottom:20px;

            &:last-child{
                margin-bottom:0;
            }

            &:hover, &:focus, &:active{
                color:#333;
                text-decoration:none;
            }
        }

    }
}

#map.contact-map{
    height: 600px;
}