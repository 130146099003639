.footer {
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 40px;
    background: $brand-primary;
}

.footer__content {
    min-width: 200px;
    width: 100%;
    margin: 0 auto;
    color: #FFFFFF;

    a {
        color: #FFFFFF;
    }
}

.footer__item {
    width: 90%;
    margin: 0 auto;
    @media (max-width: $screen-sm-max) {
        margin-top: 15px;
    }
}

.footer__item_new{
    width:100%;
    margin-top:15px;

    p{
        margin-bottom:0;
    }
}

.address-footer{
    padding:0;
    li{
        list-style-type:none;

        &:first-child{
            font-weight:bold;
        }

    }
}

.blue2-logo-footer{
    float:left;
}

.oscr-logo-footer{
    float:right;

    @media(max-width:1200px){
        width:50%;
    }

    @media(max-width:992px){
        height:80px;
        width:auto;
    }
}


.footer-social{
    padding-left:0;
    li{
        list-style-type:none;
        display:inline-block;
        margin-right:10px;

        &:last-child{
            margin-right:0;
        }

        a{
            &:hover{
                opacity:0.8;
            }
        }
    }
}